.notification {
  border-radius: 4px;
  display: grid;
  grid-gap: 10px;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  grid-template-columns: 1fr 5fr;
  border: 1px solid var(--black);
  position: relative;
  transition: 0.35s;
  transform: translateY(100%);
}

.notification.visible {
  transform: translate(0%);
}

.notification.success {
  background-color: var(--green);
}

.notification.warning {
  background-color: var(--orange);
}

.notification.danger {
  background-color: var(--red);
}

.notification img {
  justify-self: center;
  align-self: center;
  width: 40px;
}

.notification p {
  color: var(--black);
  font-weight: 500;
  align-self: center;
  padding-right: 10px;
}

.notification:hover {
  border-color: var(--black);
}
